import React from "react";
import Imgix from "react-imgix";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import { Link, navigate } from "gatsby";
import styles from "./SetUpAccount.module.scss";
import DefaultButton from "../../../../Buttons/DefaultButton";
import baseStyles from "../index.module.scss";

const DrawingSkateboard =
  "https://imgix.cosmicjs.com/00fb3cd0-3f88-11ec-99ca-fbde970234a8-bidfootsketchskateboard-1.png";

const SetUpAccount = () => (
  <div className={baseStyles.baseAuthFlowContainer}>
    <div className={baseStyles.topContainer}>
      <Imgix
        src={`${DrawingSkateboard}?auto=format`}
        height={165}
        width={172}
        htmlAttributes={{
          alt: "Set Up Account",
          src: `${DrawingSkateboard}?blur=100&px=2&auto=format`,
        }}
        attributeConfig={{
          src: "data-src",
          srcSet: "data-srcset",
          sizes: "data-sizes",
        }}
        className="lazyload blur-up"
      />
      <h3>Let&apos;s Get Started</h3>
      <h1>Connect Bidddy To Instagram</h1>
      <p>
        Congrats! Your Bidddy profle is ready and you are only steps away from
        building your first automated social auction.
        <br />
        <br />
        To get started we’ll need to connect Bidddy to your Instagram account.
        It’s easy and you only have to do it once.
      </p>
    </div>
    <div className={styles.bottomContainer}>
      <Link to="/learn/">Why You&apos;ll Love Bidddy</Link>
      <div className={styles.buttonContainer}>
        <DefaultButton
          outlined
          onClick={() => navigate("/dashboard/")}
          title="SKIP"
        />
        <DefaultButton
          onClick={() =>
            navigate("/dashboard/getting-started/connecting-bidddy/")
          }
          title="CONNECT"
        />
      </div>
    </div>
  </div>
);

export default SetUpAccount;
