import React from "react";
import { navigate } from "gatsby";
import SignUpHeader from "../../../components/PageComponents/Signup/internal/SignUpHeader";
import { AuthProvider } from "../../../components/Auth/auth";
import LoginLayout from "../../../components/LoginLayout";
import SetUpAccount from "../../../components/PageComponents/Dashboard/NewAuthFlow/AccountSetUp/SetUpAccount";

const AccountSetUp = () => (
  <AuthProvider>
    <SignUpHeader
      title="Account Set Up"
      onClose={() => navigate("/dashboard/")}
    />
    <LoginLayout hideHeader title="Bidddy - Account Set Up">
      <SetUpAccount />
    </LoginLayout>
  </AuthProvider>
);

export default AccountSetUp;
